import { Component, OnInit } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { PanelServices } from "../services/panel.service";
declare var jquery: any;
declare var $: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  userData: any = {};
  created_date = "";
  select_dropdown_content = "Categories";
  user_name = "";
  cate_id = "";
  mycate = "";
  category_name = "";
  search_string = "";
  gamecategorylist = "";
  current_route = "";
  user_img_src = "";
  user_id = 0;
  privateSection = false;
  length = 0;
  loader;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public panelService: PanelServices
  ) {
    //localStorage.setItem('userData' , JSON.stringify(''));
    var current_route = this.router.url;
    var seach_info = JSON.parse(localStorage.getItem("seachdata"));
    if (current_route.split("/")[1]) {
      if (seach_info) {
        if (seach_info == null) {
        } else if (seach_info.category_name) {
          this.select_dropdown_content = seach_info.category_name;
        }
      }
    }
    var user_data = JSON.parse(localStorage.getItem("userData"));
    //this.user_id = user_data[0]._id;
    if (user_data) {
      if (user_data == null) {
        this.privateSection = false;
      } else if (user_data[0]._id) {
        this.privateSection = true;
        this.user_id = user_data[0]._id;
        this.user_name = user_data[0].first_name;
        if (this.user_id) {
          this.user_account(this.user_id);
        }
      }
    }
  }

  ngOnInit() {
    this.get_all_game_category_list();

    $("body").on("click", ".openmenu", function (e) {
      if ($(e.target).attr("name") != "yes") {
        if ($(".mobilemenu").hasClass("mymenushow")) {
          $(".mobilemenu").removeClass("mymenushow");
        } else {
          $(".mobilemenu").addClass("mymenushow");
        }
      }
      $(e.target).attr("name", "yes");
      setTimeout(function () {
        $(e.target).attr("name", "no");
      }, 500);
    });
  }

  get_all_game_category_list() {
    this.loader = true;
    var normalizeSellerPayload = {
      apiname: "list_game_category",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        this.gamecategorylist = data.data;
      });
  }

  logoutcustomer() {
    localStorage.setItem("userData", JSON.stringify(""));
    this.router.navigate(["login"]);
  }

  user_account(user_id) {
    this.loader = true;
    var normalizeSellerPayload = {
      user_id: user_id,
      apiname: "user_account",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        if (data.status == 201) {
          this.panelService.openSnackBar(data.message, "OK", "errorMsg");
        } else {
          if (data.data[0]["user_image"] != "profile.png") {
            this.user_img_src = data.data[0]["user_image"];
          } else {
            this.user_img_src =
              this.panelService.returnbaseUrl() + "/" + "profile.png";
          }
        }
      });
  }

  block_account() {
    this.loader = true;
    var normalizeSellerPayload = {
      userstatus: "remove",
      user_id: this.user_id,
      apiname: "block_account",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        if (data.status == 201) {
          this.panelService.openSnackBar(data.message, "OK", "errorMsg");
        } else {
          this.panelService.openSnackBar(data.message, "OK", "successMsg");
        }
      });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.go_to_search_page_query();
      //$('.search_icon').trigger('click');
      // rest of your code
    }
  }

  select_header_dropdown_content(cate_id, mycate, category_slug) {
    this.cate_id = cate_id;
    this.mycate = mycate;
    //var category_slug = category_slug.toLowerCase()
    var searchinfo = {
      cate_id: cate_id,
      category_slug: category_slug,
      category_name: mycate,
    };
    localStorage.setItem("seachdata", JSON.stringify(searchinfo));
    var category_name = this.mycate;
    this.router.navigate(["redirect/", category_slug]);
  }

  go_to_search_page_query() {
    var category_name = $("#my_cat_name").val();
    var my_cat_id = $("#my_cat_id").val();
    if (this.search_string) {
      this.router.navigate(["search"], {
        queryParams: {
          category_id: my_cat_id,
          category_name: category_name,
          searchby: this.search_string,
        },
      });
    }
  }
}
