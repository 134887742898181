import { Injectable, EventEmitter } from "@angular/core";
import {
  Http,
  Headers,
  RequestOptions,
  URLSearchParams,
  Response,
  ResponseOptions,
} from "@angular/http";
import "rxjs/Rx";
// import 'rxjs/add/operator/catch';
import { Observable } from "rxjs/Observable";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Location } from "@angular/common";
import { HttpHeaders , HttpClient } from "@angular/common/http";
@Injectable()
export class PanelServices {
  //baseUrl = "https://examwire-api.herokuapp.com/";
  // baseUrl: string = "http://localhost:3001/";
  // baseUrl: string = "http://3.7.254.6:3001/";
 // baseUrl: string = "http://localhost:3001/";
   baseUrl: string = "https://api.examswire.com/";
  mainUrl = "https://exam.com/";
  shareReferUrl = "https://exam.com";
  shareUrl;
  apiKey;
  userData = JSON.parse(localStorage.getItem("userData"));
  productMetaObject: any[] = [
    { tableName: "menu_category" },
    { tableName: "menu_subcategory" },
    { tableName: "product_category" },
    { tableName: "product_subcategory" },
  ];

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    public http: Http,
    private snackBar: MatSnackBar,
    private location: Location,
    public httpclient: HttpClient,

  ) { }

  returnbaseUrl() {
    return this.baseUrl;
  }
  returnmainUrl() {
    return this.mainUrl;
  }
  openSnackBar(msg: any, action: any, msgClass) {
    this.snackBar.open(msg, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    $(".cdk-overlay-container")
      .removeClass("errorMsg")
      .removeClass("successMsg")
      .addClass(msgClass);
  }
  handleError(err) {
    let responseOptions = new ResponseOptions({
      status: err.status,
      statusText: err.statusText,
    });
    if (err.status == 500) {
      this.openSnackBar("Something went wrong", "Ok", "errorMsg");
      return Observable.throw(new Response(responseOptions));
    }
    if (err.status == 401) {
      this.openSnackBar("Unauthenticated", "Ok", "errorMsg");
      return Observable.throw(new Response(responseOptions));
    }
    return Observable.throw(err);
  }

  productsMetaDataList(): Observable<any> {
    let header = new Headers({ Accept: "application/json" });
    let options = new RequestOptions({ headers: header });

    return this.http
      .post(
        this.baseUrl + "sellero/products/metadata",
        this.productMetaObject,
        options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getTestData() {
    let header = new Headers({
      Accept: "application/json",
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .get(this.baseUrl + "test-data", options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  testData(data) {
    let header = new Headers({
      Accept: "application/json",
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "test-data", data, options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  testPaperList() {
    console.log("================================    07");

    // let header = new Headers({
    //   Accept: "application/json",
    //   Authorization:
    //     JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    // });
    // let options = {
      let token= <string>JSON.parse(localStorage.getItem("FeenuAdminData")).token
      let headers = new HttpHeaders()
        .set('Accept', "application/json")
       .set('Authorization', token)
        // Accept: "application/json",
        // Authorization:JSON.parse(localStorage.getItem("FeenuAdminData")).token,
      
    // }
    return this.httpclient.post(this.baseUrl + "get_all_paper_list",{}, { 'headers': headers})

  }

  uploadQuestionImages(data, apiname) {
    return this.http
      .post(this.baseUrl + apiname, data)
      .map((response: Response) => {
        return response.json();
      })
  }

  comman_service_funcation(data) {
    console.log(data)
    var admin_data = JSON.parse(localStorage.getItem("FeenuAdminData"));
    if (admin_data) {
      let header = new Headers({
        Accept: "application/json",
        Authorization:
          JSON.parse(localStorage.getItem("FeenuAdminData")).token,
      });
      let options = new RequestOptions({ headers: header });
      return this.http
        .post(this.baseUrl + data.apiname, data, options)
        .map((response: Response) => {
          return response.json();
        })
        .catch((err) => {
          return this.handleError(err);
        });
    } else {
      /* let header = new Headers({ 'Accept': 'application/json' , Authorization:
      "Bearer " + JSON.parse(localStorage.getItem("userData")).token}); */
      let options = new RequestOptions();

      return this.http
        .post(this.baseUrl + data.apiname, data, options)
        .map((response: Response) => {
          return response.json();
        })
        .catch((err) => {
          return this.handleError(err);
        });
    }
  }
  check_seller_auth() {
    var VeggmiSellerData = JSON.parse(localStorage.getItem("VeggmiSellerData"));
    return VeggmiSellerData;
  }
  logoutseller() {
    localStorage.setItem("VeggmiSellerData", JSON.stringify(""));
    var VeggmiSellerData = JSON.parse(localStorage.getItem("VeggmiSellerData"));
    return VeggmiSellerData;
  }

  check_admin_auth() {
    var VeggmiAdminData = JSON.parse(localStorage.getItem("VeggmiAdminData"));
    return VeggmiAdminData;
  }
  logoutAdmin() {
    localStorage.setItem("VeggmiAdminData", JSON.stringify(""));
    var VeggmiAdminData = JSON.parse(localStorage.getItem("VeggmiAdminData"));
    return VeggmiAdminData;
  }

  getReferCode() {
    let length = Object.keys(this.userData).length;
    if (length != 0) {
      if (this.userData.refer_code) {
        return "/referred/" + this.userData.refer_code;
        //return '?refer_code='+this.userData.refer_code;
      } else {
        return "";
      }
    }
    return "";
  }
  openNativeShare(shareUrl) {
    let text = ``;

    if (this.userData.api_token) {
      let name = "";
      name = this.userData.first_name ? this.userData.first_name : "";
      name += this.userData.last_name ? this.userData.last_name : "";
      text = `Meet Shado - Your Personal Carbon Footprint ${name}@OnlyPlanet.in. I just did!`;
    } else {
      text = `@OnlyPlanet.in`;
    }

    let newVariable: any;
    newVariable = window.navigator;
    newVariable
      .share({
        title: "",
        text: text,
        url: shareUrl,
      })
      .then((e) => { });
  }

  checkIfUserOnMobile(): boolean {
    if (navigator) {
      var ua = navigator.userAgent;
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
          ua
        )
      ) {
        // if website open in mobile
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  openShareWindowForWeb(action, shareUrl, video?) {
    let myUrl = "";
    switch (action) {
      case "facebook":
        myUrl = "https://www.facebook.com/sharer/sharer.php?u=";
        break;
      case "whatsapp":
        if (this.checkIfUserOnMobile()) {
          myUrl = "whatsapp://send?text=";
        } else {
          myUrl = "https://web.whatsapp.com/send?text=";
        }
        break;
      case "googleplus":
        myUrl = "https://plus.google.com/share?url=";
        break;
      case "twitter":
        myUrl = "https://twitter.com/intent/tweet?url=";
        break;
      case "skype":
        myUrl = "https://web.skype.com/share?url=";
        break;
      case "linkedin":
        myUrl = "https://www.linkedin.com/shareArticle?mini=true&url=";
        break;
      case "mail":
        myUrl = "mailto:?body=";
        break;
      case "pinterest":
        myUrl = "http://pinterest.com/pinthis?url=";
        break;
    }
    if (video) {
      myUrl += video;
    } else {
      myUrl += shareUrl;
    }

    if (action == "googleplus" || action == "skype") {
      if (this.userData.api_token) {
        let name = "";
        name = this.userData.first_name ? this.userData.first_name : "";
        name += this.userData.last_name ? this.userData.last_name : "";
        myUrl += `&text=Meet Shado - Your Personal Carbon Footprint ${name}@feenu.in. I just did!`;
      } else {
        myUrl += `&text=@feenu.in`;
      }
    }

    let a = document.createElement("a") as HTMLAnchorElement;
    a.href = myUrl;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // window.open(myUrl, '_blank');
    // shareWindow.focus();
  }

  addReward(data) {
    let header = new Headers({
      Accept: "application/json",
    });
    let options = new RequestOptions({
      headers: header,
    });
    let params = new URLSearchParams();
    params.append("api_token", this.apiKey);
    params.append("type", data.type);
    params.append("detail", data.detail);
    return this.http
      .post(this.baseUrl + `add-reward`, params, options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }
}
