//Modules
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MetaModule } from "ng2-meta";
import { MetaGuard } from "ng2-meta";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
// import { CKEditorModule } from 'ngx-ckeditor';

import { addquestionsModule } from "./create_paper/addquestions.module";

import * as $ from "jquery";

import {
  MatDatepickerModule,
  MatInputModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatDialogModule,
} from "@angular/material"; /**/

import { RouterModule, Routes } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ServiceWorkerModule } from "@angular/service-worker";

import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import {} from "../environments/environment";

//Services
import { PanelServices } from "./services/panel.service";

//Components
import { AppComponent } from "./app.component";

import { adstxtComponent } from "./footer/adstxt.component";
import { NgxDateRangePickerModule } from "ngx-daterangepicker";

import { redirectComponent } from "./redirect/redirect.component";

import { environment } from "../environments/environment.prod";
import { ModalLoaderComponent } from "./modal-loader/modal-loader.component";
import { feenuLoaderComponent } from "./loader/feenuloader.component";

// image cropper
import { ImageCropperModule } from "ngx-image-cropper";

// auth guard
import { AuthGuard } from "./auth.guard";

import { GoogleAnalyticsEventsServiceService } from "./google-analytics-events-service.service";
//
import { ApiServices } from "./services/api.service";

import { NgxPermissionsModule } from "ngx-permissions";

import { SharedModule } from "./shared/shared.module";
import { AddQuestionsComponent } from "./create_paper/add-questions.component";
import { KeyvaluePipe } from "./keyvalue.pipe";

import {NgxImageCompressService} from 'ngx-image-compress';
import { AppService } from "./services/app.service";

const appRoutes: Routes = [
  {
    path: "adminlogin",
    loadChildren: "./login/login.module#loginModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Admin Login" } },
  },
  {
    path: "testdata",
    loadChildren: "./test-data/test-data.module#testDataModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Test Data" } },
  },
  {
    path: "maincategory",
    loadChildren: "./category/maincategory.module#maincategoryModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "maincategory" } },
  },
  {
    path: "category",
    loadChildren: "./category/category.module#categoryModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "category" } },
  },
  {
    path: "subcategory",
    loadChildren: "./category/subcategory.module#subcategoryModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "subcategory" } },
  },
  {
    path: "addsubcategory/:sub_cat_id",
    loadChildren: "./category/addsubcategory.module#addsubcategoryModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "addsubcategory" } },
  },
  {
    path: "examdescription",
    loadChildren: "./examdescription/examdescription.module#examdescriptionModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "examdescription" } },
  },
  {
    path: "addexamdescription/:sub_cat_id",
    loadChildren: "./examdescription/addexamdescription.module#addexamdescriptionModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "addexamdescription" } },
  },
  {
    path: "cutoff",
    loadChildren: "./cutoff/cutoff.module#cutoffModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "cutoff" } },
  },
  {
    path: "addcutoff/:cut_off_id",
    loadChildren: "./cutoff/addcutoff.module#addcutoffModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "addcutoff" } },
  },
  {
    path: "add_paper/:id",
    loadChildren: "./create_paper/addpaper.module#addpaperModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "add_paper" } },
  },
  {
    path: "add_questions/:paperId",
    // loadChildren: "./create_paper/addpaper.module#addpaperModule",
    loadChildren: "./create_paper/addquestions.module#addquestionsModule",
    // component: AddQuestionsComponent,
    canActivate: [MetaGuard],
    data: { meta: { title: "add_questions" } },
  },
  {
    path: "list_questions/:paperId",
    loadChildren: "./create_paper/listquestions.module#listquestionsModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "list_questions" } },
  },
  {
    path: "edit_question/:questionId",
    loadChildren: "./create_paper/editquestion.module#editquestionModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "edit_question" } },
  },
  {
    path: "create_paper",
    loadChildren: "./create_paper/create_paper.module#create_paperModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "subcategory" } },
  },
  {
    path: "publiccontent",
    loadChildren: "./publiccontent/publiccontent.module#publiccontentModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Public Contents" } },
  },
  {
    path: "customer-resetpassword/:verification_code",
    loadChildren: "./user/reset_password.module#reset_passwordModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "ResetPassword",
      },
    },
  },

  {
    path: "user-edit/:user_id",
    loadChildren: "./userlist/user_edit.module#user_editModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "edit user" } },
  },

  {
    path: "redirect/:newpageurl",
    component: redirectComponent,
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "",
      },
    },
  },

  {
    path: "u/:user_id",
    loadChildren: "./user_profile/user_profile.module#user_profileModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "",
      },
    },
  },

  {
    path: "staffprofile",
    loadChildren: "./staff/staffprofile.module#staffprofileModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "staffprofile" } },
  },

  {
    path: "userlist",
    loadChildren: "./userlist/userlist.module#userlistModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "User List" } },
  },
  {
    path: "change-password",
    loadChildren: "./user/change_password.module#changepasswordModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "",
      },
    },
  },
  {
    path: "",
    loadChildren: "./login/login.module#loginModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Admin Login" } },
  },
  {
    path: "forgetpassword",
    loadChildren: "./user/forget_password.module#forgetpasswordModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "",
      },
    },
  },
  {
    path: "changeprofile",
    loadChildren: "./user/changeprofile.module#changeprofileModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "changeprofile",
      },
    },
  },
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#dashboardModule",
    canActivate: [MetaGuard],
    data: {
      meta: {
        title: "exams",
        description: "",
      },
    },
  },
  {
    path: "page-not-found",
    loadChildren: "./page_not_found/page_not_found.module#page_not_foundModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "page-not-found" } },
  },
  {
    path: "admin-forgetpassword",
    loadChildren: "./login/forget_password.module#forgetpasswordModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Admin Forget Password" } },
  },
  {
    path: "admin-resetpassword/:verification_code",
    loadChildren: "./login/resetpassword.module#resetpasswordModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Admin Reset Password" } },
  },
  {
    path: "question-paper-image-upload",
    loadChildren: "./question-paper-image-upload/imageUploadList.module#imageUploadListModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Image Upload" } },
  },
  {
    path: "image-upload",
    loadChildren: "./question-paper-image-upload/addImageUpload.module#addImageUploadModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Image Upload" } },
  },
  {
    path: "image-upload/:imageId",
    loadChildren: "./question-paper-image-upload/addImageUpload.module#addImageUploadModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Image Upload" } },
  },
  {
    path: "blogs",
    loadChildren: "./blogs/blogs.module#BlogsModule",
    canActivate: [MetaGuard],
    data: { meta: { title: "Blog" } },
  },
  { path: "**", redirectTo: "dashboard" },
];

@NgModule({
  declarations: [
    AppComponent,
    redirectComponent,
    adstxtComponent,
    ModalLoaderComponent,
    feenuLoaderComponent,
    KeyvaluePipe,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    ServiceWorkerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    CKEditorModule,

    RouterModule.forRoot(appRoutes),
    ImageCropperModule,
    NgxDateRangePickerModule,
    MetaModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [
    PanelServices,
    ApiServices,
    AppService,
    AuthGuard,
    GoogleAnalyticsEventsServiceService,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
