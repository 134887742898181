import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { MetaConfig, MetaService } from "ng2-meta";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";

import { PanelServices } from "../services/panel.service";

declare var jquery: any;
declare var $: any;
@Component({
  selector: "app-adstxt",
  templateUrl: "./adstxt.component.html",
  styleUrls: ["./adstxt.component.css"],
})
export class adstxtComponent implements OnInit {
  public Editor = ClassicEditor;
  contact_name = "";
  contact_email = "";
  contact_number = "";
  contact_url = "";
  contact_facebook = "";
  contact_twitter = "";
  contact_address = "";
  contact_youtube = "";
  contact_pinterest = "";
  google_plus = "";
  content = "";
  content_title = "";
  contactus_content_id = "";
  loader;
  constructor(
    public router: Router,
    private panelService: PanelServices,
    private metaService: MetaService,
    private location: Location
  ) {
    $("body").removeClass("body_img");
  }

  ngOnInit() {
    this.get_public_aboutus_info();
  }

  get_public_aboutus_info() {
    this.loader = true;
    var normalizeSellerPayload = {
      apiname: "by_id_public_content",
      content_id: "5d049813126bdc0eb3a29a98",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        this.content = data.data.content;
        this.content_title = data.data.content_title;
      });
  }
}
