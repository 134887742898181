import { Injectable, EventEmitter } from "@angular/core";
import {
  Http,
  Headers,
  RequestOptions,
  URLSearchParams,
  Response,
  ResponseOptions,
} from "@angular/http";
import "rxjs/Rx";
// import 'rxjs/add/operator/catch';
import { Observable } from "rxjs/Observable";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Location } from "@angular/common";

@Injectable()
export class ApiServices {
  //baseUrl = "https://examwire-api.herokuapp.com/";
 // baseUrl: string = "http://localhost:3001/";
  // baseUrl: string = "http://3.7.254.6:3001/";
  // "http://api.examswire.com/";
  //  baseUrl: string = "http://3.7.254.6:3001";
   baseUrl: string = "https://api.examswire.com/";

  horizontalPosition: MatSnackBarHorizontalPosition = "end";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(
    public http: Http,
    private snackBar: MatSnackBar,
    private location: Location
  ) {}

  openSnackBar(msg: any, action: any, msgClass) {
    this.snackBar.open(msg, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    $(".cdk-overlay-container")
      .removeClass("errorMsg")
      .removeClass("successMsg")
      .addClass(msgClass);
  }

  handleError(err) {
    let responseOptions = new ResponseOptions({
      status: err.status,
      statusText: err.statusText,
    });
    if (err.status == 500) {
      this.openSnackBar("Something went wrong", "Ok", "errorMsg");
      return Observable.throw(new Response(responseOptions));
    }
    if (err.status == 401) {
      this.openSnackBar("Unauthenticated", "Ok", "errorMsg");
      return Observable.throw(new Response(responseOptions));
    }
    return Observable.throw(err);
  }

  login(data) {
    let header = new Headers({ Accept: "application/json" });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "admin-login", data, options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  addStaff(data) {
    let header = new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        JSON.parse(localStorage.getItem("VeggmiAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    let httpParams = new URLSearchParams();
    httpParams.set("email", data.email);
    httpParams.set("role", data.role);
    httpParams.set("password", data.password);
    httpParams.set("name", data.name);
    return this.http
      .post(this.baseUrl + "users", httpParams.toString(), options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  editStaff(data) {
    let header = new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        JSON.parse(localStorage.getItem("VeggmiAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    let httpParams = new URLSearchParams();
    httpParams.set("name", data.name);
    httpParams.set("email", data.email);
    httpParams.set("role", data.role);
    httpParams.set("accessLevel", data.accessLevel);
    return this.http
      .patch(this.baseUrl + `users/${data._id}`, httpParams.toString(), options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getStaff() {
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("VeggmiAdminData")).token,
    });
    let httpParams = new URLSearchParams();
    httpParams.set("page", "1");
    httpParams.set("limit", "10");
    httpParams.set("sort", "name");
    httpParams.set("filter", '{"role":"user"}');
    httpParams.set("order", "-1");
    let options = new RequestOptions({ headers: header, search: httpParams });
    return this.http
      .get(this.baseUrl + "users", options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getSystemAccess() {
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("VeggmiAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .get(this.baseUrl + "system/all", options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  editMyprofile(data) {
    let header = new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        JSON.parse(localStorage.getItem("VeggmiAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    let httpParams = new URLSearchParams();
    httpParams.set("name", data.name);
    httpParams.set("email", data.email);
    httpParams.set("role", data.role);
    httpParams.set("accessLevel", data.accessLevel);
    return this.http
      .put(this.baseUrl + `users`, httpParams.toString(), options)
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  // Newly implemneted Api calls

  deletePaper(data) {
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "delete_paper", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  addQuestion(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "save_question", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getPaper(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "get_paper", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getQuestions(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "get_questions", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  getQuestion(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "get_question", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  updateQuestion(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "update_question", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  deleteQuestion(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "delete_question", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }


  bulkUploadQuestion(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "bulk_upload_questions", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }


  bulkUploadAnswer(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "bulk_upload_answers", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }


  generateAnswerFormate(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http
      .post(this.baseUrl + "generate_ans_format", data, options
      )
      .map((response: Response) => {
        return response.json();
      })
      .catch((err) => {
        return this.handleError(err);
      });
  }

  //======================Blogs======================//

  getBlogs(){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "blog_list", options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  addNewBlog(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "add_new_blog", data, options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  updateBlog(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "update_blog", data, options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  deleteBlog(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "delete_blog", data, options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  updateBlogLIstPageDetails(data){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "update_blog_list_page_details", data, options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  getBlogLIstPageDetails(){
    let header = new Headers({
      Authorization:
        JSON.parse(localStorage.getItem("FeenuAdminData")).token,
    });
    let options = new RequestOptions({ headers: header });
    return this.http.post(this.baseUrl + "get_blog_list_page_details", {}, options).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

  getFooterData(){
    return this.http.post(this.baseUrl + "get_footer_data", {}).map((response: Response) => {
      return response.json();
    }).catch((err) => {
      return this.handleError(err);
    });
  }

}
