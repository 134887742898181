import { Component, OnInit } from "@angular/core";
import { PanelServices } from "../services/panel.service";
import { MatButtonToggleChange } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
// import {StripeCheckoutLoader, StripeCheckoutHandler} from 'ng-stripe-checkout';
// declare let paypal: any;

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.css"],
})
export class redirectComponent implements OnInit {
  // private stripeCheckoutHandler: StripeCheckoutHandler;
  loader = true;
  newpageurl = "";
  gameurl = "";

  constructor(
    private panelService: PanelServices,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.newpageurl = activatedRoute.snapshot.params.newpageurl;
    this.gameurl = activatedRoute.snapshot.params.gameurl;
  }

  ngOnInit() {
    this.router.navigate(["c/" + this.newpageurl]);

    if (this.gameurl) {
      this.router.navigate(["g/" + this.gameurl]);
    }
  }
}
