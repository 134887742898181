import { Injectable } from '@angular/core';

@Injectable()
export class AppService {

  blog_details:any;

  constructor() { }

}
