import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { feenuLoaderComponent } from '../feenuloader/feenuloader.component';
import { SidebarComponent } from "../sidebar/sidebar.component";
import { NavbarComponent } from "../navbar/navbar.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

@NgModule({
  declarations: [
    LoaderComponent,
    feenuLoaderComponent,
    SidebarComponent,
    FooterComponent,NavbarComponent
  ],
  exports: [
    LoaderComponent,FormsModule, ReactiveFormsModule,ImageCropperModule,feenuLoaderComponent,
    SidebarComponent,NavbarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,FormsModule, ReactiveFormsModule,ImageCropperModule,
    RouterModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ]
})
export class SharedModule { }
