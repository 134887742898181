import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(public router: Router,
              public activatedRoute: ActivatedRoute){
    
  }

  ngOnInit(){
    this.changeModalBtnBckColor();
  }

  changeModalBtnBckColor(){
    this.router.events.subscribe((ev)=>{
      if(ev instanceof NavigationEnd){
         window.scrollTo(0, 0)
        let element = document.querySelector('.main_card_wrapper');
        if(element){
          let style = getComputedStyle(element);
          let bck = style.getPropertyValue('background-image');
          let btn = document.getElementById('btn_color_modal');
          let btn2 = document.getElementById('btn_color_modal2');
          btn.style.background = bck;
          btn2.style.background = bck;
        }
      }
    });
  }

  
}


// "background_color" : #2fd104

// green
// "theme_color": "#01579B",

// black
// "theme_color": "#000",

// blue
// "theme_color": "#2196F3",