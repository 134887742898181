import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PanelServices } from "../services/panel.service";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  created_date = "";
  created_time = "";
  game_category_id = 0;
  gamelist = "";
  first_name = "";
  last_name = "";
  user_name = "";
  email_id = "";
  declarativeFormCaptchaValue = "";
  pass = "";
  confirm_pass = "";
  gender = "";
  user_email = "";
  user_password = "";
  length = 0;
  loader;
  auth_logn: any = "";

  constructor(private router: Router, public panelService: PanelServices) {}

  ngOnInit() {
    var d = new Date();
    var month = d.getMonth() + 1;
    var day = d.getDate();

    this.created_date =
      d.getFullYear() +
      "-" +
      (month < 10 ? "0" : "") +
      month +
      "-" +
      (day < 10 ? "0" : "") +
      day;
    this.created_time =
      (d.getHours() < 10 ? "0" : "") +
      d.getHours() +
      ":" +
      (d.getMinutes() < 10 ? "0" : "") +
      d.getMinutes() +
      ":" +
      (d.getSeconds() < 10 ? "0" : "") +
      d.getSeconds();

    var set_cookies = localStorage.getItem("cookiekey");
    if (set_cookies != "#100") {
      this.show_cookies();
    }
  }

  /*
 ,
		error => {
			this.errorMessage = <any>error
		}
  logout(){
    this._auth.logout().subscribe(
      (data)=>{
        //return a boolean value.
      }
    )
  }*/
  onregSubmit() {
    if (!this.first_name) {
      this.panelService.openSnackBar(
        "First name is required",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (!this.last_name) {
      this.panelService.openSnackBar("Last name is required", "Ok", "errorMsg");
      return;
    }
    if (!this.user_name) {
      this.panelService.openSnackBar("User Name is required", "Ok", "errorMsg");
      return;
    }
    var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (!EMAIL_REGEXP.test(this.email_id)) {
      this.panelService.openSnackBar(" Email not valid", "Ok", "errorMsg");
      return;
    }
    if (!this.gender) {
      this.panelService.openSnackBar("gender is required", "Ok", "errorMsg");
      return;
    }
    if (!this.pass) {
      this.panelService.openSnackBar("Password is required", "Ok", "errorMsg");
      return;
    }
    if (this.confirm_pass != this.pass) {
      this.panelService.openSnackBar(
        "Confirm password not match",
        "Ok",
        "errorMsg"
      );
      return;
    }
    this.loader = true;
    var normalizeSellerPayload = {
      first_name: this.first_name,
      last_name: this.last_name,
      user_name: this.user_name,
      email_id: this.email_id,
      gender: this.gender,
      userpassword: this.pass,
      userstatus: "Active",
      user_image: "profile.png",
      created_date: this.created_date,
      created_time: this.created_time,
      google_plus_link: "",
      twitter_link: "",
      fb_link: "",
      profile_show: "Public",
      apiname: "user_registeration",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        if (data.status == 201) {
          this.panelService.openSnackBar(data.message, "OK", "errorMsg");
        } else {
          this.panelService.openSnackBar(data.message, "OK", "successMsg");
          $("#browseRegister .close").trigger("click");
          $("#browseRegister").removeClass("show");

          this.first_name = "";
          this.last_name = "";
          this.email_id = "";
          this.gender = "";
          this.pass = "";
        }
      });
  }

  onSubmit() {
    if (!this.user_email) {
      this.panelService.openSnackBar(
        "User email is required",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (!this.user_password) {
      this.panelService.openSnackBar(
        "Password name is required",
        "Ok",
        "errorMsg"
      );
      return;
    }

    this.loader = true;
    var normalizeSellerPayload = {
      user_email: this.user_email,
      user_password: this.user_password,
      apiname: "userlogin",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        if (data.status == 201) {
          this.panelService.openSnackBar(data.message, "OK", "errorMsg");
        } else {
          localStorage.setItem("userData", JSON.stringify(data.data));
          this.panelService.openSnackBar(data.message, "OK", "successMsg");
          $("#browseLogin .close").trigger("click");
          $("#browseLogin").removeClass("show");
          //this.router.navigate(['home']);
          window.location.reload();
          //$("#browseLogin").modal('hide');
        }
      });
  }

  pop_hide_show() {
    $("#browseLogin .close").trigger("click");
    $("#browseLogin").removeClass("show");
    setTimeout(function () {
      $("#browseRegister").modal("show");
      //$("#browseLogin").modal('hide');
      // $("#browseLogin").remove();
    }, 1000);
  }

  show_cookies() {
    $("#popup").delay(2000).fadeIn();
    $("#popup-close").click(function (
      e // You are clicking the close button
    ) {
      $("#popup").fadeOut(); // Now the pop up is hiden.
      localStorage.setItem("cookiekey", "#100");
    });
    $("#popup").click(function (e) {
      $("#popup").fadeOut();
    });
  }
}
