import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { PanelServices } from "../services/panel.service";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  current_password = "";
  new_password = "";
  confirm_password = "";
  loader;
  veggmi_admin_id = "";
  menulist = "";
  veggmi_admin_name = "";
  feenu_admin_name = "";
  submodule_link = "";
  feenu_admin_id = "";
  veggmi_admin_img = "";
  veggmi_admin_img_src = "";
  admin_sub_menu: any[];
  admin_menu: any[];
  constructor(
    public router: Router,
    private panelService: PanelServices,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    var admin_data = JSON.parse(localStorage.getItem("FeenuAdminData"));
    this.admin_sub_menu = JSON.parse(localStorage.getItem("Feenumenu"));
    //this.submodule_link = activatedRoute.snapshot.params.urllink;

    var url = window.location.href;
    var segments = url.split("/");
    var segments4 = "";
    this.submodule_link = segments[3];
    segments4 = segments[4];
    /* if(segments4){
			 this.submodule_link = segments[3]+'/'+segments4;
		}else{
				
		} */
    var invalidurl = this.submodule_link;
    var isfound = "false";
    $.each(this.admin_sub_menu, function (i, value) {
      if (value.submodule_link.indexOf(invalidurl) != -1) {
        isfound = "true";
      } else {
        //isfound = 'false';
      }
      if (invalidurl == "staffprofile" || invalidurl == "dashboard") {
        isfound = "true";
      }
    });
    if (isfound != "true") {
      //this.panelService.openSnackBar('this page access not define', 'Ok','errorMsg');
      //this.router.navigate(['/dashboard']);
    }

    if (!admin_data) {
      this.panelService.openSnackBar(
        "Please Login to continue",
        "Ok",
        "errorMsg"
      );
      this.router.navigate(["/adminlogin"]);
    } else {
      this.feenu_admin_name = admin_data.user.name;
      this.feenu_admin_id = admin_data.user._id;
    }

    /*  else if(!admin_data.user._id){
			this.panelService.openSnackBar('Please Login to continue', 'Ok','errorMsg');
			this.router.navigate(['/adminlogin']);
		} */
    // if(VeggmiAdminData == null){
    // 	this.panelService.openSnackBar('Please Login to continue', 'Ok','errorMsg');
    // 	this.router.navigate(['/login']);
    // }else if(!VeggmiAdminData.veggmi_admin_id){
    // 	this.panelService.openSnackBar('Please Login to continue', 'Ok','errorMsg');
    // 	this.router.navigate(['/login']);
    // }else{
    // 	this.veggmi_admin_id = VeggmiAdminData.veggmi_admin_id;
    // 	this.veggmi_admin_name = VeggmiAdminData.full_name;
    // 	this.veggmi_admin_img = VeggmiAdminData.admin_img;
    // 	this.veggmi_admin_img_src = (VeggmiAdminData.admin_img);
    // }
  }

  ngOnInit() {
    // this.get_all_access_menu();
    this.access_menu();
  }

  openMeMenuClick() {
    $(".treeview").removeClass("menu-open");
    //$(this).closeet('ul').closeet('li').removeClass('menu-open');
  }
  openMeMenuDefault() {}

  access_menu() {
    let admin_menu = [];
    $.each(this.admin_sub_menu, function (i, val) {
      var xx = admin_menu.filter((p) => p.module_id == val.module_id);
      if (jQuery.isEmptyObject(xx)) {
        admin_menu.push(val);
      }
    });

    this.admin_menu = admin_menu;
  }

  /* get_all_access_menu(){
		 this.loader = true;
		var normalizeSellerPayload = {
		   'admin_id': this.feenu_admin_id,
			'apiname': 'get_all_access_menu'
		}
		this.panelService.comman_service_funcation(normalizeSellerPayload)
			.subscribe( data => {
				this.loader = false;
				this.menulist = data.data;
				
				
		});
	} */

  onChangePassowrdSubmit() {
    if (!this.current_password) {
      this.panelService.openSnackBar(
        "Current Password is required",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (!this.new_password) {
      this.panelService.openSnackBar(
        "New Password is required",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (!this.confirm_password) {
      this.panelService.openSnackBar(
        "Confirm Password is required",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (this.confirm_password != this.new_password) {
      this.panelService.openSnackBar(
        "Confirm Password should same as new password",
        "Ok",
        "errorMsg"
      );
      return;
    }
    var LENGTH_REGEXP = /^([a-zA-Z0-9@$!%*#?&\s]{6,})$/;
    if (!LENGTH_REGEXP.test(this.new_password)) {
      this.panelService.openSnackBar(
        "New Password must be at least 6 characters.",
        "Ok",
        "errorMsg"
      );
      return;
    }
    if (this.confirm_password != this.new_password) {
      this.panelService.openSnackBar(
        "Confirm Password should same as new password",
        "Ok",
        "errorMsg"
      );
      return;
    }
    this.loader = true;
    var normalizeSellerPayload = {
      current_password: this.current_password,
      new_password: this.new_password,
      feenu_admin_id: this.feenu_admin_id,
      apiname: "admin_change_password",
    };
    this.panelService
      .comman_service_funcation(normalizeSellerPayload)
      .subscribe((data) => {
        this.loader = false;
        if (data.status == 201) {
          this.panelService.openSnackBar(data.message, "OK", "errorMsg");
        } else {
          this.panelService.openSnackBar(data.message, "OK", "successMsg");
          $("#browseChangePassword .close").trigger("click");
          $("#browseChangePassword").removeClass("show");
          //$("#browseChangePassword").modal('hide');
        }
      });
  }

  logoutadmin() {
    localStorage.setItem("Feenumenu", JSON.stringify(""));
    localStorage.setItem("FeenuAdminData", JSON.stringify(""));
    this.router.navigate(["adminlogin"]);
    // window.location.reload();

    //$("#browseLogin").modal('show');
  }
}
