import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "keyvalue",
})
export class KeyvaluePipe implements PipeTransform {
  transform(obj: Object): any {
    let objArr = [];
    Object.keys(obj).forEach((k) => {
      objArr.push({
        key: k,
        value: obj[k],
      });
    });
  }
}
