import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

@Injectable()

export class AuthGuard implements CanActivate {
  constructor(private router: Router){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
        // return true;
      if(localStorage.getItem('onlyPUserData')){
          let localData = JSON.parse(localStorage.getItem('onlyPUserData'));
          if(localData.registrations_type == 'u'){
            // this.router.navigate(['infotainment']);
            return true;
          }
        // this.router.navigate(['splash']);
        return true;
      }
      else{        
        // this.router.navigate(['splash']);
        return false;
      }     
  }
}
